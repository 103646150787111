.form-check-inline .form-check-input {
    margin-left: -1.25rem;
}

.btn:focus {
    box-shadow: 0.0.0.0.2rem rgba(1,1,1,0.5);
    border-color: #000000;
}

.btn-warning.hover, .btn-warning:hover {
    background-color: #e0a800;
    border-color: #4b3800;
}
 
.form-control.focus, .form-control:focus {
    border-color: #000000;
    box-shadow: 0 0 0 0.15rem rgba(0, 100, 210, 0.89);
}

.form-control.is-invalid.focus, .form-control.is-invalid:focus {
    border-color: #000000;
    box-shadow: 0 0 0 0.15rem #dc3545;
}

.btn-secondary {
    background-color: #767676;
}