ol.progtrckr {
  list-style-type: none;
  padding: 0;
  display: table;
  margin: 0 auto 2em auto;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}
ol.progtrckr li span {
  display: none;
}

ol.progtrckr li.progtrckr-doing span {
  padding: 0 1rem;
  display: inline;
}


.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -4rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

/* ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
} */

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: #cccccc;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

/* ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
  background-color: #ff4500;
} */

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

/* ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
} */

.footer-buttons button {
  margin: .5em;
  position: absolute;
  bottom: 0px;
}

.footer-buttons .float-left {
  left: 0px;
}

.footer-buttons .float-right {
  right: 0px;
}
@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
  
  ol.progtrckr li.progtrckr-doing {
    border-bottom-width: 2px;
  }

  ol.progtrckr li.progtrckr-doing span {
    display: none;
  }
  .progtrckr em {
    display: none;
  }
}