html {
  height: 100%;
}
body {
  min-height: 100%;
  font-family: "PT Sans", sans-serif;
}
#root {
  min-height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto auto 1fr auto;
  grid-template-rows: auto auto 1fr auto;
}
.navbar {
  font-family: "Rockwell";
  border: none;
  margin: 0;
}

.navbar-expand{
  justify-content: space-between;
}
.banner {
  margin: 0px;
  padding: 0 1.5em;
  -ms-grid-row: 2;
  grid-row: 2;
}

.banner img {
  max-width: 75%;
  padding: 0.5rem;
}

.banner h1 {
  font-size: 1.5rem;
  color: white;
  padding: 0.5rem 0;
}

@media only screen and (max-width: 600px) {
  .navbar {
    display: block;
    text-align: center;
  }
  .navbar * {
    margin: auto;
    text-align: center;
  }
}

h2 {
  font-size: 24px; 
}

.form-label {
  line-height: 1.5;
  font-size: 20px;
}

main {
  -ms-grid-row: 3;
  grid-row: 3;
  width: 90%;
  max-width: 900px;;
  margin: 1em auto;
}

form {
  min-height: 100%;
  height: auto;
  padding-bottom: 3.5em;
}

.footer_region {
  background: #242424;
  -ms-grid-row: 4;
  grid-row: 4;
  display: table;
  width: 100%;
}
.footer_links {
  display: table;
  background: #242424;
  padding: 0.5em 2em;
  color: white;
  margin: 0;
}
.footer_links a {
  color: white;
  font-size: 0.8rem;
}

.footer_links ul {
  list-style-type: none;
  background: #242424;
  padding: 0.5em 2em;
  color: white;
}

.footer_links li {
  color: white;
  font-size: 0.8rem;
  display: inline-block;
}

@media (min-width: 600px) {
  .footer_links {
    text-align: center;
  }
  .footer_links a {
    display: inline-block;
  }
  .footer_links a + a:before {
    content: "|";
    padding: 0 0.5em;
    font-size: 1rem;
  }
  
  .footer_links li + li:before {
    content: "|";
    padding: 0 0.5em;
    font-size: 1rem;
  }
}

.modal-dialog {
  max-width: 90%;
  width: 500px;
  margin: auto;
}

.version {
  text-align: center;
  color: #FFFFFF;
  font-size: 0.6rem;
  margin-bottom: 0.6rem;
}